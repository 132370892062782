<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'company-report-work', params: { cid } }"><a>{{ $t('breadcrumb_company_report_work') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <div class="columns">
          <div class="column">
            <sqr-input-week
              class="field"
              label="company_report_work_from"
              v-model="from"
            />
          </div>
          <div class="column">
            <sqr-input-week
              class="field"
              label="company_report_work_until"
              v-model="until"
            />
          </div>
          <div class="column">
            <report-buttons @selected="select" />
          </div>
        </div>
      </div>
    </section>
    <table class="table is-fullwidth is-hoverable" v-if="company && org">
      <thead>
        <tr>
          <td></td>
          <td
            class="has-text-centered tooltip"
            :data-tooltip="$t('company_report_work_sheets_count')"
          >
            <fa :icon="['fal', 'square']" />
          </td>
          <td
            class="has-text-centered tooltip"
            :data-tooltip="$t('company_report_work_sheets_accepted')"
          >
            <fa :icon="['fal', 'check']" />
          </td>
          <td
            class="has-text-centered tooltip"
            :data-tooltip="$t('company_report_work_sheets_warning')"
          >
            <fa :icon="['fal', 'exclamation-circle']" />
          </td>
          <td
            class="has-text-centered tooltip"
            :data-tooltip="$t('company_report_work_sheets_error')"
          >
            <fa :icon="['fal', 'exclamation-triangle']" />
          </td>
          <td
            v-for="mod in summaryModels"
            :key="mod.op"
            class="has-text-centered tooltip"
            :data-tooltip="mod | name"
          >
            <fa :icon="['fal', mod.icon]" />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry in org.content" :key="entry.id">
          <template v-if="entry.type === 'team'">
            <td
              class="has-background-light has-text-weight-semibold"
              :colspan="summaryModels.length + 5"
            >
              {{ entry.name }}
            </td>
          </template>
          <template v-if="entry.type === 'account' && loading[entry.id]">
            <td>
              <router-link
                :to="{
                  name: 'sheets-year',
                  params: { cid, aid: entry.id, year }
                }"
              >
                <account-name :account="entry" reverse />
              </router-link>
            </td>
            <td :colspan="summaryModels.length + 4">
              <sqr-progress v-show="loading[entry.id]" />
            </td>
          </template>
          <template
            v-if="
              entry.type === 'account' &&
                !loading[entry.id] &&
                !exists[entry.id]
            "
          >
            <td>
              <router-link
                :to="{
                  name: 'sheets-year',
                  params: { cid, aid: entry.id, year }
                }"
              >
                <account-name :account="entry" reverse />
              </router-link>
            </td>
            <td :colspan="summaryModels.length + 4">
              <sqr-button
                label="company_report_work_build"
                size="small"
                @click="build(entry.id)"
                :is-loading="building[entry.id]"
              />
            </td>
          </template>
          <template
            v-if="
              entry.type === 'account' && !loading[entry.id] && exists[entry.id]
            "
          >
            <td>
              <router-link
                :to="{
                  name: 'sheets-year',
                  params: { cid, aid: entry.id, year }
                }"
              >
                <account-name :account="entry" reverse />
              </router-link>
              <sqr-button
                icon="sync"
                tooltip="company_report_work_build"
                color="white"
                size="small"
                @click="build(entry.id)"
                :is-loading="building[entry.id]"
              />
            </td>
            <td>{{ reports[entry.id].sheetsCount }}</td>
            <td>
              <span
                v-if="reports[entry.id].acceptedCount"
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                {{ reports[entry.id].acceptedCount }}
              </span>
            </td>
            <td>
              <span
                v-if="reports[entry.id].noticesCount.warning"
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
              >
                {{ reports[entry.id].noticesCount.warning || 0 }}
              </span>
            </td>
            <td>
              <span
                v-if="reports[entry.id].noticesCount.danger"
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800"
              >
                {{ reports[entry.id].noticesCount.danger }}
              </span>
            </td>
            <td
              v-for="icon in summaryModelsIcons"
              :key="icon"
              class="has-text-right"
            >
              {{ reports[entry.id].summariesByIcon[icon] | opDuration }}
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<style scoped>
.table thead tr th,
.table thead tr td {
  position: sticky;
  background-color: white;
  top: 3.25rem;
  z-index: 1;
}
</style>

<script>
import { mapActions, mapState } from 'vuex';
import { DateTime } from 'luxon';
import { fromPairs } from 'ramda';
import company from './company';

import SqrProgress from '@/sqrd/SqrProgress';
import SqrInputWeek from '@/sqrd/SqrInputWeek';
import SqrButton from '@/sqrd/SqrButton';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
import ReportButtons from '@/components/ReportButtons';

import yearBoundaries from '@/utils/yearBoundaries';

import name from '@/filters/name';
import opDuration from '@/filters/opDuration';

import buildRef from '@/utils/buildRef';
import buildReportSheets from '@/utils/buildReportSheets';

import summaryModels from 'sqr-wotime-core/common/summaryModels';
import computeReportSheets from 'sqr-wotime-core/reports/sheets';

export default {
  name: 'CompanyReportWork',
  mixins: [company],
  components: {
    SqrInputWeek,
    CompanyName,
    AccountName,
    ReportButtons,
    SqrProgress,
    SqrButton
  },
  filters: { name, opDuration },
  computed: {
    ...mapState('org', {
      org: 'record',
      orgLoading: 'loading',
      orgLoadError: 'error'
    }),
    ...mapState('companyReportSheets', {
      pathes: 'pathes',
      loading: 'loading',
      loadError: 'loadError',
      exists: 'exists',
      docs: 'docs'
    }),
    summaryModels() {
      return summaryModels();
    },
    summaryModelsIcons() {
      return this.summaryModels.map(e => e.icon);
    },
    year() {
      return this.from?.substring(0, 4);
    },
    reports() {
      const query = { startWeek: this.from, endWeek: this.until };
      return fromPairs(
        Object.entries(this.docs).map(([accountId, doc]) => {
          const sheets = Object.values(doc.sheetsById);
          const report = computeReportSheets(query, sheets);
          const summariesByIcon = fromPairs(
            report.summaries.map(sum => [sum.icon, sum])
          );
          return [accountId, { ...report, summariesByIcon }];
        })
      );
    }
  },
  data() {
    return {
      from: '',
      until: '',
      //
      building: {}
    };
  },
  mounted() {
    this.select(new Date().getFullYear());
  },
  methods: {
    ...mapActions('org', { orgSub: 'sub' }),
    ...mapActions('companyReportSheets', ['sub']),
    async load() {
      try {
        const from = DateTime.fromISO(this.from);
        const until = DateTime.fromISO(this.from);
        const weekYear = from.weekYear;

        if (from > until) return;
        if (from.weekYear < 2000) return;
        if (from.weekYear !== until.weekYear) return;

        const org = await this.orgSub({
          path: ['companies', this.cid, 'orgs'],
          id: 'default'
        });
        const content = org?.content ?? [];
        const pairs = content
          .filter(item => item.type === 'account')
          .map(({ id }) => [
            id,
            [
              'companies',
              this.cid,
              'accounts',
              id,
              'reports',
              `${weekYear}-sheets`
            ]
          ]);
        const pathes = fromPairs(pairs);
        this.sub({ pathes });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    select(weekYear) {
      const res = yearBoundaries(weekYear);
      this.from = res.weekFrom;
      this.until = res.weekUntil;
      this.load();
    },
    async build(aid) {
      try {
        this.$set(this.building, aid, true);
        const report = await buildReportSheets(this.cid, aid, this.from);
        const path = this.pathes[aid];
        // console.log(this.pathes, aid, path);
        await buildRef(path).set(report);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.$delete(this.building, aid);
      }
    }
  }
};
</script>
